@use './partials/reset';
@use './partials/colors' as *;

@import url('https://fonts.googleapis.com/css2?family=Fraunces:ital,opsz,wght@0,9..144,400;0,9..144,700;1,9..144,400;1,9..144,600&display=swap');

*,*::before, *::after {
  font-family: 'Fraunces', serif;
  font-style: normal;
  color: $text;
}

// Global wrapper

.wrapper {
  width: 90vw;
  margin-inline: auto;
}

body {
  scroll-behavior: smooth;
  position: relative;
}
