@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;

.menu {
  display: flex;
  background: $bg-color;
  justify-content: flex-end;
  padding: 1rem;
  
  &__button {
    max-width: 2rem;
    cursor: pointer;
    transition: all 100ms ease-out;
    &:hover {
      transform: scale(1.2) rotate(90deg);
      filter: drop-shadow(0 5px 0 $accent);

    }
  }

  &__overlay {
    z-index: 555;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: clamp(4rem, 6.3rem, 6.6rem);
    position: absolute;
    top: 0;
    background: rgb(0, 3, 17);
    left: 0;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;

    @include tablet {
      font-size: 10rem;
    }
  }

  &__link {
    text-decoration: none;
    color: orange;
    transition: all .2s ease-in-out;
    &:hover {
      color: orangered;
      letter-spacing: 5px;
      transform:rotate3d(3, -4, 1, 25deg)
    }
  }
}
