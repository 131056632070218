@use "../../styles/partials/colors" as *;
@use "../../styles/partials/mixins" as *;

.portfolioItem {
  padding-block: 3rem;
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    margin: 5rem;
    justify-content: center;
  }

  &__image {
    position: relative;
    margin-bottom: 3rem;
    order: 1;

    @include tablet {
      order: 0;
      margin-right: 5rem;
    }
  }

  &__shape {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    height: 130%;
    transform: translate(-25%, -10%);
    z-index: -11;

    @include tablet {
      height: 130%;
      transform: translate(-55%, -10%);
    }

  }

  &__thumbnail {
    display: block;
    width: 75vw;
    height: 50vw;
    margin: 0 auto;
    background-color: $image-bg;
    background-size: cover;
    border-radius: 10px;
    border: 1px solid $image-border;
    object-fit:fill;
    object-position: center;

    @include tablet {
      width: 40vw;
      height: 35vw;
    }
    
    @include desktop {
      width: 35vw;
      height: 25vw;
    }
  }

  &__links {
    position: relative;
    display: flex;
    margin: 1rem auto;
    justify-content: center;
    gap: 1rem;

    @include tablet {
      flex-direction: column;
      position: absolute;
      right: 0;
      top: 25%;
      transform: translateX(20%);
      gap: 1rem;
    }

  }

  &__link {
    border: 2px solid white;
    text-align: center;
    display: inline-block;
    color: white;
    text-decoration: none;
    background-color: $bg-color;
    padding: 1rem 2rem;
    min-width: 5rem;
    border-radius: 10px;
    transition: all 150ms ease-in-out;

    &:hover {
      transform: scale3d(1.05,1.05,1);
      box-shadow: 0 12px 6px -10px rgba(0,0,0,0.7);
    }

    @include tablet {
      border: none;
    }

    &--secondary {
      background-color: $accent;
    }
  }

  &__info {
    max-width: 75vw;
    margin: 2rem auto;
    
    @include tablet {
      max-width: 35vw;
    }
  }

  &__title {
    color: $accent;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;  
  }

  &__copy {
    font-size: 1.2rem;
    line-height: 1.4;
  }

  &__tech {
    font-size: 0.8rem;
    letter-spacing: 0.3px;
    font-weight: 400;
    flex-wrap: wrap;
    margin-block: 1rem;
    display: flex;
    gap:0.5rem;

    &Item {
    color: white;
    border-radius: 5px;
    padding: 0.3rem 1rem;
    background-color: $tech;
    }
  }

}


.backgroundShape {
  fill: orange !important;
}