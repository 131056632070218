$tablet: 768px;
$desktop: 1280px;

// Media Quieries

@mixin tablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}
