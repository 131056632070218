@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;


.contact {
  margin-inline: auto;
  padding: 2rem;
  border: 10px solid $bg-color;
  margin-block: 2rem;
  text-align: center;
  margin-bottom: 10rem;

  @include tablet {
    width: 80vw;
  }

  @include desktop {
    width: 30vw;
  }

  &__title {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  &__text {
    line-height: 1.3;
    color: $accent;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  &__link {
    margin-bottom: 1rem;
  }
}