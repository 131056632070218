@use '../../styles/partials/colors' as *;
@use '../../styles/partials/mixins' as *;

.skills {
  background-color: $bg-color;
  margin-inline: auto;
  display: flex;
  justify-content: space-between;
  padding-block: 10rem 5rem;

  &__wrapper {
    width: 90vw;
    margin-inline: auto;
  }

  &__title {
    font-size: 4rem;
    color: white;
    text-align: center;
  }

  &__lists {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-block: 4rem;
    text-align: center;

    @include tablet {
      text-align: initial;
      flex-direction: row;
    }

  }

  &__list {
    list-style-image: url('../../assets/images/list-icon.svg');
  }

  &__list-item {
    font-size: 1.5rem;
    color: $accent;
    margin-bottom: 1rem;
  }

}